import React, { useContext, useEffect, useState } from 'react';

import Header from '~/components/header';
import Footer from '~/components/footer';
import Menu from '~/components/menu';
import TableWithLinks from '~/components/table/TableWithLinks';
import IndicatorCard from '~/components/card/IndicatorCard';
import { getByCompany, save, saveRetorno } from '~/service/VisitasService';
import { AuthContext } from '~/context/AuthContext';
import { showAlertDanger, showAlertSuccess } from '~/components/notification';
import { closeModal, downloadDocuments, openModal } from '~/utils/util';

import * as yup from 'yup';

import ModalVisita from './modalVisita';
import ModalRetorno from './modalRetorno';
import CardHeader from '~/components/card/CardHeader';
import moment from 'moment';
import BasicInput from '~/components/input/BasicInput';
import { useForm } from 'react-hook-form';
import { baseURL } from '~/config/api';

const schemaVisitas = yup.object().shape({
  emp_codigo: yup.string().required('Selecione um associado.'),
  usu_codigo: yup.string().required('Selecione um gerente.'),
  uva_data: yup.string().required('Informe a data e hora da visita.'),
  rva_codigo: yup.string().required('Selecione um tipo de retorno.'),
});
const schemaRetorno = yup.object().shape({
  rva_titulo: yup.string().required('Informe o título do retorno.'),
});

const CadastroVisitas: React.FC = () => {
  const { company, user } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [visitas, setVisitas] = useState([]);
  const [filteredVisitas, setFilterVisitas] = useState([]);
  const [tiposRetornos, setTipoRetornos] = useState([]);
  const [associados, setAssociados] = useState([]);
  const [gerentes, setGerentes] = useState([]);
  const [isAwaitResponse, setIsAwaitResponse] = useState(false);
  const [render, setRender] = useState(true);
  const [searchBoxValue, setSearchBoxValue] = useState('');
  const [filterServerSide, setFilterSErverSide] = useState({
    data_inicio: moment().startOf('month').format('YYYY-MM-DD'),
    data_final: moment().endOf('month').format('YYYY-MM-DD'),
  });
  const [totais, setTotais] = useState({
    totalVisitas: 0,
    totalAssociadosVisitados: 0,
    totalAssociadosSemVisita: 0,
  });

  const { control, handleSubmit } = useForm();

  React.useEffect(() => {
    if (render) {
      setIsLoading(true);

      getByCompany(company.EMP_CODIGO, filterServerSide.data_inicio, filterServerSide.data_final).then(
        ({
          visitas = [],
          associados = [],
          gerentes = [],
          total_visitas_mes = 0,
          total_visitas_geral = 0,
          total_associados_sem_visita = 0,
          tipos_retorno = [],
        }) => {
          setTotais({
            totalVisitas: total_visitas_mes,
            totalAssociadosVisitados: total_visitas_geral,
            totalAssociadosSemVisita: total_associados_sem_visita,
          });
          setTipoRetornos(tipos_retorno);
          setVisitas(visitas);
          setFilterVisitas(visitas);
          setAssociados(associados);
          setGerentes(gerentes);
          setIsLoading(false);
          setRender(false);
        }
      );
    }
  }, [render]);

  useEffect(() => {
    setFilterVisitas(
      visitas.filter((visita: any) => {
        const searchBoxValueLower = searchBoxValue.toLowerCase();
        if (searchBoxValue === '') return true;
        return (
          visita.EMP_RAZAOSOCIAL.toLowerCase().includes(searchBoxValueLower) ||
          visita.USU_NOME.toLowerCase().includes(searchBoxValueLower)
        );
      })
    );
  }, [searchBoxValue]);

  const formSubmitHandler = async (payload: any, reset: any) => {
    setIsAwaitResponse(true);

    const { success, error } = await save(payload);

    setIsAwaitResponse(false);

    setRender(success);

    if (success) {
      showAlertSuccess({ message: 'Visita cadastrada com sucesso.' });
      closeModal('modalVisias');
      reset();
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
      if (error) console.log(error);
    }
  };

  const submithandlerRetorno = async (payload: any, reset: any) => {
    setIsAwaitResponse(true);

    payload.usu_codigo = user.USU_CODIGO;

    const { success, error } = await saveRetorno(payload);

    setIsAwaitResponse(false);

    setRender(success);

    if (success) {
      showAlertSuccess({ message: 'Retorno cadastrado com sucesso.' });
      closeModal('modalRetono');
      reset();
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
      if (error) console.log(error);
    }
  };

  const handlerFilterServerSide = async (data: any) => {
    setFilterSErverSide(data);
    setRender(true);
    closeModal('ModalFilterVisistas');
  };

  const exportToExcel = () => {
    const params = new URLSearchParams();

    params.append('empCodigo', company.EMP_CODIGO.toString());

    if (filterServerSide.data_inicio) params.append('inicio', filterServerSide.data_inicio);
    if (filterServerSide.data_final) params.append('fim', filterServerSide.data_final);

    const url = `${baseURL}/api/visitas/export-excel?${params.toString()}`;

    downloadDocuments(url, `Visitas Associadso-${filterServerSide.data_inicio ?? 'arquivo'}.xls`, false);
  };

  return (
    <div className="wrapper">
      <Menu />

      <div className="main-panel">
        <Header title="Visitas" searchBoxState={{ searchBoxValue, setSearchBoxValue }} />

        <div className="content">
          {/*  {isLoading && <PageLoading />} */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <IndicatorCard
                  icon="far fa-calendar-alt text-info"
                  label="Visitas geral"
                  value={totais.totalVisitas.toString()}
                  title="Total de visitas"
                />
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <IndicatorCard
                  icon="fas fa-calendar-day text-success"
                  label="Associados visitados"
                  value={totais.totalAssociadosVisitados.toString()}
                  title="Total de associados visitados no mes"
                />
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <IndicatorCard
                  icon="far fa-calendar text-danger"
                  label="Associados sem visita"
                  value={totais.totalAssociadosSemVisita.toString()}
                  title="Associados sem visita no mes"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card table-big-boy">
                  <CardHeader
                    title="Visitas realizadas"
                    subtitle="Aplique os filtros para alterar a visualização do relatório"
                    filterAction={() => openModal('ModalFilterVisistas')}
                    extraActions={[
                      { title: 'Exportar para Excel', iconClass: 'far fa-file-excel', action: exportToExcel },
                    ]}
                  />
                  <div className="card-header">
                    <button
                      type="button"
                      onClick={() => openModal('modalVisias')}
                      className="btn btn-sm btn-success btn-outline"
                      style={{ marginTop: 5 }}
                    >
                      <span className="btn-label">
                        <i className="fa fa-plus"></i>
                      </span>
                      cadastrar visita
                    </button>
                    <button
                      type="button"
                      onClick={() => openModal('modalRetono')}
                      className="btn btn-sm btn-success btn-outline ml-3"
                      style={{ marginTop: 5 }}
                    >
                      <span className="btn-label">
                        <i className="fa fa-plus"></i>
                      </span>
                      cadastrar tipo retorno
                    </button>
                  </div>
                  <div className="card-body table-full-width">
                    <TableWithLinks
                      isLoading={isLoading}
                      emptyTableMessage="Nenhum registro de visita encontrada."
                      columns={[
                        { field: 'UVA_DATA', label: 'Data', alignment: 'text-left' },
                        { field: 'EMP_RAZAOSOCIAL', label: 'Associado', alignment: 'text-left' },
                        { field: 'USU_NOME', label: 'Gerente', alignment: 'text-left' },
                        { field: 'UVA_DESCRIPTION', label: 'Observação', alignment: 'text-left' },
                        { field: 'RVA_TITULO', label: 'Tipo Retorno', alignment: 'text-center' },
                      ]}
                      data={filteredVisitas}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>

      <ModalVisita
        onSubmit={formSubmitHandler}
        associados={associados}
        retornos={tiposRetornos}
        gerentes={gerentes}
        isAwaitResponse={isAwaitResponse}
        schema={schemaVisitas}
      />
      <ModalRetorno
        retornos={tiposRetornos}
        isAwaitResponse={isAwaitResponse}
        schema={schemaRetorno}
        onSubmit={submithandlerRetorno}
      />

      <div className="modal fade" id="ModalFilterVisistas" role="dialog" tabIndex={-1}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body" style={{ paddingTop: 0 }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="card stacked-form">
                    <div className="card-header ">
                      <h4 className="card-title">Buscar visitas...</h4>
                    </div>
                    <div className="card-body " style={{ paddingTop: 0 }}>
                      <form onSubmit={handleSubmit(handlerFilterServerSide)} id="ModalFilterVisistasId">
                        <BasicInput
                          control={control}
                          type="date"
                          defaultValue={moment().subtract(3, 'd').format('YYYY-MM-DD')}
                          name="data_inicio"
                          label="Data início"
                        />

                        <BasicInput
                          control={control}
                          type="date"
                          defaultValue={moment().add(3, 'd').format('YYYY-MM-DD')}
                          name="data_final"
                          label="Data fim"
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
                <i className="fa fa-arrow-left"></i> Voltar
              </button>
              <button className="btn btn-default btn-wd" form="ModalFilterVisistasId" type="submit">
                <i className="fa fa-search"></i> Buscar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CadastroVisitas;
