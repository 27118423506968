import api, { defaultMessageError } from '~/config/api';

export const getTransactions = async ({ data_inicio = null, data_final = null, one_status = null }) => {
  try {
    const { data } = await api.post('/api/transacao/carregar-todos', {
      data_inicio,
      data_final,
      one_status,
    });
    return data;
  } catch (error) {
    return error;
  }
};
export const getHistory = async ({ orc_codigo }) => {
  try {
    const { data } = await api.get(`/api/transacao/carregar-historico?orc_codigo=${orc_codigo}`);
    return data;
  } catch (error) {
    return error;
  }
};
export const cancelar = async (orc_codigo) => {
  try {
    const { data } = await api.get(`/api/transacao/cancelar?orc_codigo=${orc_codigo}`);
    return data;
  } catch (error) {
    return error;
  }
};
