import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// import { ToastContainer } from 'react-toastify';

import LayoutMain from '~/layout/main';

import { EmpType } from '~/enum';
import { MenuRouteSettings, RouteSettings } from '~/types';

import PrivateRoutes from './PrivateRoutes';

import Login from '~/pages/login';
import Error403 from '~/pages/error/error403';
import Error404 from '~/pages/error/error404';
import ErrorCatcher from '~/pages/error/ErrorCatcher';

import AssociadoOfertas from '~/pages/associado/verOfertas';
import AssociadoDashboard from '~/pages/associado/dashboard';
import AssociadoAssociados from '~/pages/associado/verAssociados';
import AssociadoCriarOfertas from '~/pages/associado/criarOfertas';
import AssociadoNegociacoes from '~/pages/associado/negociacoes';
import AssociadoBaixarVoucher from '~/pages/associado/baixarVoucher';
import AssociadoExtratoConta from '~/pages/associado/extratoConta';

import FranquiaDashboard from '~/pages/franquia/dashboard';
import FranquiaBase from '~/pages/franquia/base';
import FranquiaRaioXAssociado from '~/pages/franquia/raioXAssociado';

import FranquiaEquipesAssociados from '~/pages/franquia/carteiraAssociados';
import FranquiaEquipesConsultores from '~/pages/franquia/carteiraConsultores';

import FranquiaOfertas from '~/pages/franquia/verOfertas';
import FranquiaAssociados from '~/pages/franquia/verAssociados';
import FranquiaLiberarOfertas from '~/pages/franquia/liberarOfertas';
import FranquiaNegociacoes from '~/pages/franquia/negociacoes';
import FranquiaParametros from '~/pages/franquia/parametros';
import FranquiaCadastroUsuarios from '~/pages/franquia/cadastroUsuarios';
import FranquiaCadastroVisistas from '~/pages/franquia/cadastroVisistas';
import FranquiaCadastroAssociados from '~/pages/franquia/cadastroAssociados';
import FranquiaCadastroContaBancaria from '~/pages/franquia/cadastroContaBancaria';
import FranquiaFinanceiro from '~/pages/franquia/financeiro';
import FranquiaBalancete from '~/pages/franquia/relatorioBalancete';
import FranquiaContasPagarReceber from '~/pages/franquia/relatorioContasPagarReceber';
import FranquiaFluxoCaixa from '~/pages/franquia/relatorioFluxoCaixa';
import FranquiaRelatorioSaldoAssociados from '~/pages/franquia/relatorioSaldoAssociados';

import FranqueadoraDashboard from '~/pages/franqueadora/dashboard';
import FranqueadoraBase from '~/pages/franqueadora/base';
import FranqueadoraRaioXAssociado from '~/pages/franqueadora/raioXAssociado';
import FranqueadoraCadastroFranqueado from '~/pages/franqueadora/cadastroFranqueado';
import FranqueadoraCadastroUsuarios from '~/pages/franqueadora/cadastroUsuarios';
import FranqueadoraCadastroBensOfertados from '~/pages/franqueadora/cadastroBensOfertados';
import FranqueadoraCadastroSegmentos from '~/pages/franqueadora/cadastroSegmentos';
import FranqueadoraCadastroFormaPagamento from '~/pages/franqueadora/cadastroFormaPagamento';
import FranqueadoraVerOfertas from '~/pages/franqueadora/verOfertas';
import FranqueadoraVerAssociados from '~/pages/franqueadora/cadastroAssociados';
import FranqueadoraLiberarAssociados from '~/pages/franqueadora/liberarAssociados';
import FranqueadoraFinanceiro from '~/pages/franqueadora/financeiro';
import FranqueadoraBalancete from '~/pages/franqueadora/relatorioBalancete';
import FranqueadoraContasPagarReceber from '~/pages/franqueadora/relatorioContasPagarReceber';
import FranqueadoraFluxoCaixa from '~/pages/franqueadora/relatorioFluxoCaixa';
import FranqueadoraRelatorioAsssociadosXVpmix from '~/pages/franqueadora/relatorioAsssociadosXVPMIX';
import FranqueadoraRelatorioOfertasVpmix from '~/pages/franqueadora/relatorioOfertasVpmix';
import FranqueadoraRelatorioSaldoAssociados from '~/pages/franqueadora/relatorioSaldoAssociados';
import FranqueadoraParametros from '~/pages/franqueadora/parametros';
import FranqueadoraLimiteCredito from '~/pages/franqueadora/limiteCredito';
import FranqueadoraAcessarEntidades from '~/pages/franqueadora/acessarEntidades';
import FranqueadoraCancelarTransacao from '~/pages/franqueadora/cancelarTransacao';
import FranqueadoraEditarOrcamentos from '~/pages/franqueadora/editarOrcamentos';
import FranqueadoraRoyalties from '~/pages/franqueadora/royalties';
import FranqueadoraTransferenciaAssociados from '~/pages/franqueadora/transferenciaAssociados';

import ComumMeuPerfil from '~/pages/comum/meuPerfil';

import CompartilharOferta from '~/pages/nao-autenticado/CompartilharOferta';

const menus: Array<RouteSettings | MenuRouteSettings> = [
  {
    path: '/associado/dashboard',
    component: AssociadoDashboard,
    exact: true,
    type: EmpType.ASSOCIADO,
    show: true,
    label: 'Dashboard',
    iconClass: 'nc-icon nc-chart-pie-35',
  },
  {
    path: '/associado/ofertas',
    component: AssociadoOfertas,
    exact: true,
    type: EmpType.ASSOCIADO,
    show: true,
    label: 'Ver Ofertas',
    iconClass: 'nc-icon nc-favourite-28',
  },
  {
    path: '/associado/associados',
    component: AssociadoAssociados,
    exact: true,
    type: EmpType.ASSOCIADO,
    show: true,
    label: 'Associados',
    iconClass: 'nc-icon nc-bank',
  },
  {
    path: '/associado/criar-ofertas',
    component: AssociadoCriarOfertas,
    exact: true,
    type: EmpType.ASSOCIADO,
    show: true,
    label: 'Minhas Ofertas',
    iconClass: 'nc-icon nc-layers-3',
  },
  {
    path: '/associado/negociacoes',
    component: AssociadoNegociacoes,
    exact: true,
    type: EmpType.ASSOCIADO,
    show: true,
    label: 'Negociações',
    iconClass: 'nc-icon nc-bell-55',
  },
  {
    path: '/associado/baixar-voucher',
    component: AssociadoBaixarVoucher,
    exact: true,
    type: EmpType.ASSOCIADO,
    show: true,
    label: 'Baixar Vouchers',
    iconClass: 'nc-icon nc-settings-90',
  },
  {
    path: '/associado/extrato-conta',
    component: AssociadoExtratoConta,
    exact: true,
    type: EmpType.ASSOCIADO,
    show: true,
    label: 'Extrato Conta',
    iconClass: 'nc-icon nc-notes',
  },
  {
    path: '/associado/meu-perfil',
    component: ComumMeuPerfil,
    exact: true,
    type: EmpType.ASSOCIADO,
    show: false,
  },
  {
    label: 'Dashboard',
    iconClass: 'nc-icon nc-chart-pie-35',
    type: EmpType.FRANQUIA,
    routes: [
      {
        path: '/franquia/dashboard',
        component: FranquiaDashboard,
        exact: true,
        type: EmpType.FRANQUIA,
        show: true,
        label: 'Comercial',
        iconClass: 'nc-icon nc-chart-pie-35',
      },
      {
        path: '/franquia/base',
        component: FranquiaBase,
        exact: true,
        type: EmpType.FRANQUIA,
        show: true,
        label: 'Base',
        iconClass: 'nc-icon nc-chart-pie-35',
      },
      {
        path: '/franquia/raio-x-associado',
        component: FranquiaRaioXAssociado,
        exact: true,
        type: EmpType.FRANQUIA,
        show: true,
        label: 'Raio X associado',
        iconClass: 'nc-icon nc-chart-pie-35',
      },
    ],
  },
  {
    label: 'Cadastros',
    iconClass: 'nc-icon nc-simple-add',
    type: EmpType.FRANQUIA,
    routes: [
      {
        path: '/franquia/cadastro-associados',
        component: FranquiaCadastroAssociados,
        exact: true,
        type: EmpType.FRANQUIA,
        show: true,
        label: 'Associado',
      },
      {
        path: '/franquia/cadastro-conta-bancaria',
        component: FranquiaCadastroContaBancaria,
        exact: true,
        type: EmpType.FRANQUIA,
        show: true,
        label: 'Conta Bancária',
      },
      {
        path: '/franquia/cadastro-usuarios',
        component: FranquiaCadastroUsuarios,
        exact: true,
        type: EmpType.FRANQUIA,
        show: true,
        label: 'Usuários',
      },
      {
        path: '/franquia/cadastro-visitas',
        component: FranquiaCadastroVisistas,
        exact: true,
        type: EmpType.FRANQUIA,
        show: true,
        label: 'Visitas Associados',
      },
    ],
  },
  {
    label: 'Equipes',
    iconClass: 'nc-icon nc-single-02',
    type: EmpType.FRANQUIA,
    routes: [
      {
        path: '/franquia/equipe/associados',
        component: FranquiaEquipesAssociados,
        exact: true,
        type: EmpType.FRANQUIA,
        show: true,
        label: 'Equipe de associados',
        iconClass: 'nc-icon nc-single-02',
      },
      {
        path: '/franquia/equipe/consultores',
        component: FranquiaEquipesConsultores,
        exact: true,
        type: EmpType.FRANQUIA,
        show: true,
        label: 'Equipe de consultores',
        iconClass: 'nc-icon nc-single-02',
      },
    ],
  },
  {
    path: '/franquia/ofertas',
    component: FranquiaOfertas,
    exact: true,
    type: EmpType.FRANQUIA,
    show: true,
    label: 'Ver Ofertas',
    iconClass: 'nc-icon nc-favourite-28',
  },
  {
    path: '/franquia/associados',
    component: FranquiaAssociados,
    exact: true,
    type: EmpType.FRANQUIA,
    show: true,
    label: 'Ver Associados',
    iconClass: 'nc-icon nc-bank',
  },
  {
    path: '/franquia/liberar-ofertas',
    component: FranquiaLiberarOfertas,
    exact: true,
    type: EmpType.FRANQUIA,
    show: true,
    label: 'Liberar Ofertas',
    iconClass: 'nc-icon nc-check-2',
  },
  {
    path: '/franquia/negociacoes',
    component: FranquiaNegociacoes,
    exact: true,
    type: EmpType.FRANQUIA,
    show: true,
    label: 'Negociações',
    iconClass: 'nc-icon nc-bell-55',
  },
  {
    path: '/franquia/financeiro',
    component: FranquiaFinanceiro,
    exact: true,
    type: EmpType.FRANQUIA,
    show: true,
    label: 'Financeiro',
    iconClass: 'nc-icon nc-bullet-list-67',
  },
  {
    label: 'Relatórios',
    iconClass: 'nc-icon nc-paper-2',
    type: EmpType.FRANQUIA,
    routes: [
      {
        path: '/franquia/relatorio-balancete',
        component: FranquiaBalancete,
        exact: true,
        type: EmpType.FRANQUIA,
        show: true,
        label: 'Balancete',
      },
      {
        path: '/franquia/relatorio-contas-pagar-receber',
        component: FranquiaContasPagarReceber,
        exact: true,
        type: EmpType.FRANQUIA,
        show: true,
        label: 'Contas a pagar e receber',
      },
      {
        path: '/franquia/relatorio-fluxo-caixa',
        component: FranquiaFluxoCaixa,
        exact: true,
        type: EmpType.FRANQUIA,
        show: true,
        label: 'Fluxo de caixa',
      },
      {
        path: '/franquia/relatorio-saldo-associados',
        component: FranquiaRelatorioSaldoAssociados,
        exact: true,
        type: EmpType.FRANQUIA,
        show: true,
        label: 'Saldo Associados',
      },
    ],
  },
  {
    path: '/franquia/parametros',
    component: FranquiaParametros,
    exact: true,
    type: EmpType.FRANQUIA,
    show: true,
    label: 'Parâmetros',
    iconClass: 'nc-icon nc-settings-gear-64',
  },
  {
    path: '/franquia/meu-perfil',
    component: ComumMeuPerfil,
    exact: true,
    type: EmpType.FRANQUIA,
    show: false,
  },
  {
    label: 'Dashboard',
    iconClass: 'nc-icon nc-chart-pie-35',
    type: EmpType.FRANQUEADORA,
    routes: [
      {
        path: '/franqueadora/dashboard',
        component: FranqueadoraDashboard,
        exact: true,
        type: EmpType.FRANQUEADORA,
        show: true,
        label: 'Comercial',
        iconClass: 'nc-icon nc-chart-pie-35',
      },
      {
        path: '/franqueadora/base',
        component: FranqueadoraBase,
        exact: true,
        type: EmpType.FRANQUEADORA,
        show: true,
        label: 'Base',
        iconClass: 'nc-icon nc-chart-pie-35',
      },
      {
        path: '/franqueadora/raio-x-associado',
        component: FranqueadoraRaioXAssociado,
        exact: true,
        type: EmpType.FRANQUEADORA,
        show: true,
        label: 'Raio X associado',
        iconClass: 'nc-icon nc-chart-pie-35',
      },
    ],
  },

  // {
  //   label: 'Cadastro',
  //   iconClass: 'nc-icon nc-simple-add',
  //   type: EmpType.FRANQUEADORA,
  //   routes: [
  //     {
  //       path: '/franqueadora/cadastro-franqueado',
  //       component: FranqueadoraCadastroFranqueado,
  //       exact: true,
  //       type: EmpType.FRANQUEADORA,
  //       show: true,
  //       label: 'Franqueado',
  //       iconClass: 'nc-icon nc-settings-gear-64',
  //     },
  //     {
  //       path: '/franqueadora/cadastro-usuarios',
  //       component: FranqueadoraCadastroUsuarios,
  //       exact: true,
  //       type: EmpType.FRANQUEADORA,
  //       show: true,
  //       label: 'Usuários',
  //       iconClass: 'nc-icon nc-settings-gear-64',
  //     },
  //     {
  //       path: '/franqueadora/cadastro-bens-ofertados',
  //       component: FranqueadoraCadastroBensOfertados,
  //       exact: true,
  //       type: EmpType.FRANQUEADORA,
  //       show: true,
  //       label: 'Bens Ofertados',
  //       iconClass: 'nc-icon nc-settings-gear-64',
  //     },
  //     {
  //       path: '/franqueadora/cadastro-segmentos',
  //       component: FranqueadoraCadastroSegmentos,
  //       exact: true,
  //       type: EmpType.FRANQUEADORA,
  //       show: true,
  //       label: 'Segmentos',
  //       iconClass: 'nc-icon nc-settings-gear-64',
  //     },
  //     {
  //       path: '/franqueadora/cadastro-forma-pagamento',
  //       component: FranqueadoraCadastroFormaPagamento,
  //       exact: true,
  //       type: EmpType.FRANQUEADORA,
  //       show: true,
  //       label: 'Forma Pagamento',
  //       iconClass: 'nc-icon nc-settings-gear-64',
  //     },
  //   ],
  // },

  // {
  //   path: '/franqueadora/ofertas',
  //   component: FranqueadoraVerOfertas,
  //   exact: true,
  //   type: EmpType.FRANQUEADORA,
  //   show: true,
  //   label: 'Ver Ofertas',
  //   iconClass: 'nc-icon nc-favourite-28',
  // },
  // {
  //   path: '/franqueadora/associados',
  //   component: FranqueadoraVerAssociados,
  //   exact: true,
  //   type: EmpType.FRANQUEADORA,
  //   show: true,
  //   label: 'Ver Associados',
  //   iconClass: 'nc-icon nc-bank',
  // },
  // {
  //   path: '/franqueadora/liberar-associados',
  //   component: FranqueadoraLiberarAssociados,
  //   exact: true,
  //   type: EmpType.FRANQUEADORA,
  //   show: true,
  //   label: 'Liberar Associados',
  //   iconClass: 'nc-icon nc-check-2',
  // },
  // {
  //   path: '/franqueadora/financeiro',
  //   component: FranqueadoraFinanceiro,
  //   exact: true,
  //   type: EmpType.FRANQUEADORA,
  //   show: true,
  //   label: 'Financeiro',
  //   iconClass: 'nc-icon nc-bullet-list-67',
  // },
   {
     label: 'Relatórios',
     iconClass: 'nc-icon nc-paper-2',
     type: EmpType.FRANQUEADORA,
     routes: [
/*        {
         path: '/franqueadora/relatorio-balancete',
         component: FranqueadoraBalancete,
         exact: true,
         type: EmpType.FRANQUEADORA,
         show: true,
         label: 'Balancete',
       },
       {
         path: '/franqueadora/relatorio-contas-pagar-receber',
         component: FranqueadoraContasPagarReceber,
         exact: true,
         type: EmpType.FRANQUEADORA,
         show: true,
         label: 'Contas a pagar e receber',
       },
       {
         path: '/franqueadora/relatorio-fluxo-caixa',
         component: FranqueadoraFluxoCaixa,
         exact: true,
         type: EmpType.FRANQUEADORA,
         show: true,
         label: 'Fluxo de caixa',
       },
       {
         path: '/franqueadora/relatorio-asssociados-x-vpmix',
         component: FranqueadoraRelatorioAsssociadosXVpmix,
         exact: true,
         type: EmpType.FRANQUEADORA,
         show: true,
         label: 'Asssociados x VPMIX',
       },
       {
         path: '/franqueadora/relatorio-ofertas-vpmix',
         component: FranqueadoraRelatorioOfertasVpmix,
         exact: true,
         type: EmpType.FRANQUEADORA,
         show: true,
         label: 'Ofertas VPMIX',
       }, */
       {
         path: '/franqueadora/relatorio-saldo-associados',
         component: FranqueadoraRelatorioSaldoAssociados,
         exact: true,
         type: EmpType.FRANQUEADORA,
         show: true,
         label: 'Saldo Associados',
       },
     ],
   },
  // {
  //   path: '/franqueadora/parametros',
  //   component: FranqueadoraParametros,
  //   exact: true,
  //   type: EmpType.FRANQUEADORA,
  //   show: true,
  //   label: 'Parâmetros',
  //   iconClass: 'nc-icon nc-settings-gear-64',
  // },
  {
    path: '/franqueadora/limite-credito',
    component: FranqueadoraLimiteCredito,
    exact: true,
    type: EmpType.FRANQUEADORA,
    show: true,
    label: 'Limites de Crédito',
    iconClass: 'nc-icon nc-money-coins',
  },
  {
    path: '/franqueadora/meu-perfil',
    component: ComumMeuPerfil,
    exact: true,
    type: EmpType.FRANQUEADORA,
    show: false,
  },
  {
    path: '/franqueadora/cancelar-transacao',
    component: FranqueadoraCancelarTransacao,
    exact: true,
    type: EmpType.FRANQUEADORA,
    show: true,
    label: 'Cancelar Transação',
    iconClass: 'nc-icon nc-simple-remove',
  },
  // {
  //   path: '/franqueadora/acessar-entidades',
  //   component: FranqueadoraAcessarEntidades,
  //   exact: true,
  //   type: EmpType.FRANQUEADORA,
  //   show: true,
  //   label: 'Acessar Entidades',
  //   iconClass: 'nc-icon nc-refresh-02',
  // },
  // {
  //   path: '/franqueadora/cancelar-transacao',
  //   component: FranqueadoraCancelarTransacao,
  //   exact: true,
  //   type: EmpType.FRANQUEADORA,
  //   show: true,
  //   label: 'Cancelar Transação',
  //   iconClass: 'nc-icon nc-simple-remove',
  // },
  // {
  //   path: '/franqueadora/editar-orcamento',
  //   component: FranqueadoraEditarOrcamentos,
  //   exact: true,
  //   type: EmpType.FRANQUEADORA,
  //   show: true,
  //   label: 'Editar Orçamento',
  //   iconClass: 'nc-icon nc-paper-2',
  // },
  // {
  //   path: '/franqueadora/royalties',
  //   component: FranqueadoraRoyalties,
  //   exact: true,
  //   type: EmpType.FRANQUEADORA,
  //   show: true,
  //   label: 'Royalties',
  //   iconClass: 'nc-icon nc-money-coins',
  // },
  // {
  //   path: '/franqueadora/royalties/:franchiseId',
  //   component: FranqueadoraRoyalties,
  //   exact: true,
  //   type: EmpType.FRANQUEADORA,
  //   show: false,
  //   label: 'Royalties',
  //   iconClass: 'nc-icon nc-money-coins',
  // },
  {
    path: '/franqueadora/transferencia-associados',
    component: FranqueadoraTransferenciaAssociados,
    exact: true,
    type: EmpType.FRANQUEADORA,
    show: true,
    label: 'Transf. de associados',
    iconClass: 'nc-icon nc-single-02',
  },
   {
     path: '/franqueadora/associados',
     component: FranqueadoraVerAssociados,
     exact: true,
     type: EmpType.FRANQUEADORA,
     show: true,
     label: 'Associados',
     iconClass: 'nc-icon nc-bank',
   },
  {
    path: '/master-franquia/dashboard',
    component: FranquiaDashboard,
    exact: true,
    type: EmpType.MASTER_FRANQUIA,
    show: true,
    label: 'Comercial',
    iconClass: 'nc-icon nc-chart-pie-35',
  },
  {
    path: '/master-franquia/base',
    component: FranquiaBase,
    exact: true,
    type: EmpType.MASTER_FRANQUIA,
    show: true,
    label: 'Base',
    iconClass: 'nc-icon nc-chart-pie-35',
  },
  {
    path: '/master-franquia/raio-x-associado',
    component: FranquiaRaioXAssociado,
    exact: true,
    type: EmpType.MASTER_FRANQUIA,
    show: true,
    label: 'Raio X associado',
    iconClass: 'nc-icon nc-chart-pie-35',
  },
];

const isRoute = (data: RouteSettings | MenuRouteSettings): data is RouteSettings => {
  return !(data as MenuRouteSettings).routes;
};

const routes: RouteSettings[] = menus.reduce((acc, current) => {
  return isRoute(current) ? [...acc, current] : [...acc, ...current.routes];
}, [] as RouteSettings[]);

const createPrivateRoutes = (
  basePath: '/associado' | '/franquia' | '/franqueadora' | '/master-franquia',
  homepage: string,
  allowedUser: EmpType,
  routes: RouteSettings[]
) => {
  return (
    <PrivateRoutes path={basePath} allowedUser={[allowedUser]}>
      <Switch>
        {routes
          .filter((route) => route.type === allowedUser)
          .map((route) => (
            <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />
          ))}
        <Route
          key={`${basePath}/home`}
          path={basePath}
          exact
          render={() => <Redirect to={`${basePath}/${homepage}`} />}
        />
        <Route path="*" component={Error404} />
      </Switch>
    </PrivateRoutes>
  );
};

const Routes: React.FC = () => {
  return (
    <ErrorCatcher>
      <Switch>
        {createPrivateRoutes('/associado', 'ofertas', EmpType.ASSOCIADO, routes)}
        {createPrivateRoutes('/franquia', 'dashboard', EmpType.FRANQUIA, routes)}
        {createPrivateRoutes('/franqueadora', 'dashboard', EmpType.FRANQUEADORA, routes)}
        {createPrivateRoutes('/master-franquia', 'dashboard', EmpType.MASTER_FRANQUIA, routes)}

        <Route path="/compartilhar-oferta/:ofe_codigo" exact={true} component={CompartilharOferta} />
        <Route path="/nao-autorizado" exact={true} component={Error403} />
        <Route path="/" exact={true} component={Login} />
        <Route path="/*" component={Error404} />

        {/* <ToastContainer /> */}
      </Switch>
    </ErrorCatcher>
  );
};

export default Routes;
export { routes, menus, isRoute };
