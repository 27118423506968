import React, { useEffect, useRef } from 'react';
import { CardHeaderProps } from '~/types';
import logoFiltro from '~/assets/filtro_2.png';

const CardHeader: React.FunctionComponent<CardHeaderProps> = ({
  title,
  subtitle,
  filterAction,
  actions,
  legends,
  extraActions,
}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="card-header ">
      <h4 className="card-title flex-in-row">
        {title}&nbsp;
        {filterAction && (
          <img src={logoFiltro} style={{ width: 30, height: 30, cursor: 'pointer' }} onClick={() => filterAction()} />
        )}
        {extraActions?.map(({ iconClass, title, action }, i) => (
          <React.Fragment key={'CardHeader' + 'legends' + i}>
            &nbsp;
            <i className={iconClass} style={{ cursor: 'pointer' }} title={title} onClick={action} />
          </React.Fragment>
        ))}
      </h4>
      <p className="card-category">
        <span style={{ cursor: filterAction ? 'pointer' : 'text' }} onClick={filterAction}>
          {subtitle}
        </span>
      </p>
      {actions?.map(({ label, iconClass, variant, outline, action, style, disabled }, i) => (
        <button
          key={'CardHeader' + 'actions' + i}
          type="button"
          className={`btn btn-sm ${variant} ${outline || outline === undefined ? 'btn-outline' : ''} mx-1 ${
            disabled ? 'disabled pointer-events-none' : ''
          }`}
          style={{ marginTop: 10, ...style }}
          onClick={(e)=>disabled ? undefined : action(e)}
        >
          <span className="btn-label mr-1">
            <i className={iconClass}></i>
          </span>
          {label}
        </button>
      ))}
      {legends && (
        <div style={{ flexDirection: 'column', marginTop: 10 }}>
          {legends?.map(({ label, iconClass }, i) => (
            <span key={'CardHeader' + 'legends' + i} style={{ fontSize: 14, marginLeft: i > 0 ? 15 : undefined }}>
              <i className={iconClass} style={{ fontSize: 16 }} /> {label}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default CardHeader;
