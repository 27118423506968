import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import CardHeader from '~/components/card/CardHeader';
import ModalNegotiationHistory from '~/components/modal/negociacoes/ModalNegotiationHistory';
import { showAlertDanger, showAlertSuccess, showAlertWarning } from '~/components/notification';
import TableWithLinks from '~/components/table/TableWithLinks';
import LayoutMain from '~/layout/main';
import { closeModal, downloadDocuments, openModal, formatNumberToMoney } from '~/utils/util';

import { cancelar, getHistory, getTransactions } from '~/service/transacao/Transacao';
import moment from 'moment';
import { AuthContext } from '~/context/AuthContext';
import { baseURL } from '~/config/api';
import BasicInput from '~/components/input/BasicInput';
import { useForm } from 'react-hook-form';

const statusStyle: { [key: string]: { value: string; iconClass: string } } = {
  F: { value: 'Fechado', iconClass: 'fas fa-check-double' },
  E: { value: 'Em Negociação', iconClass: 'fas fa-check' },
  R: { value: 'Recusado', iconClass: 'fas fa-times' },
};

const CancelarTransacao: React.FunctionComponent = () => {
  const { company } = React.useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const [render, setRender] = useState(true);

  const [searchBoxValue, setSearchBoxValue] = useState('');

  const [filterServerSide, setFilterServerSide] = useState({
    data_inicio: moment().startOf('month').format('YYYY-MM-DD'),
    data_final: moment().endOf('month').subtract(15, 'day').format('YYYY-MM-DD'),
  });

  const [sweetAlertDeleteTransaction, setSweetAlertDeleteTransaction] = useState<JSX.Element | undefined>(undefined);

  const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  const [transacoes, setTransacoes] = useState([]);

  const [historico, setHistorico] = useState([]);

  const { control, handleSubmit } = useForm();

  React.useEffect(() => {
    if (render) {
      setIsLoading(true);

      fetchData(filterServerSide);
    }
  }, [render]);

  useEffect(() => {
    setTransacoes(
      transacoes.filter((visita: any) => {
        const searchBoxValueLower = searchBoxValue.toLowerCase();
        if (searchBoxValue === '') return true;
        return (
          visita.OFE_TITULO.toLowerCase().includes(searchBoxValueLower) ||
          visita.SOLICITANTE.toLowerCase().includes(searchBoxValueLower)
        );
      })
    );
  }, [searchBoxValue]);

  const fetchData = async (filters: any) => {
    console.log(filters);

    const { success, error, payload } = await getTransactions(filters);

    setIsLoading(false);
    if (success) {
      setTransacoes(payload);
    } else {
      showAlertWarning({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
      if (error) console.log(error.message);
    }
  };

  const fetchDataHistory = async (id: number) => {
    setIsLoadingHistory(true);
    const { success, error, payload } = await getHistory({ orc_codigo: id });

    if (success) {
      setHistorico(payload);
      setIsLoadingHistory(false);
    } else {
      showAlertWarning({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
      if (error) console.log(error.message);
    }
  };

  const handlerFilterServerSide = async (data: any) => {
    setIsLoading(true);
    setFilterServerSide(data);
    fetchData(data);

    closeModal('ModalFilterVisistas');
  };

  const exportToExcel = () => {
    const params = new URLSearchParams();

    if (filterServerSide.data_inicio) params.append('data_inicio', filterServerSide.data_inicio);
    if (filterServerSide.data_final) params.append('data_final', filterServerSide.data_final);

    const url = `${baseURL}/api/transacao/exportar-excel?${params.toString()}`;

    downloadDocuments(url, `Orcamentos-${filterServerSide.data_inicio ?? 'arquivo'}.xls`, false);
  };

  const openHistory = (transaction: any) => {
    fetchDataHistory(transaction.OFE_CODIGO);
    openModal('verHistorico');
  };

  const handleDeleteTransaction = (transaction: any) => {
    cancelar(transaction.OFE_CODIGO).then((response) => {
      if (response.success) {
        showAlertSuccess({ message: 'Transação cancelada com sucesso.' });
        fetchData(filterServerSide);
        setSweetAlertDeleteTransaction(undefined);
      } else {
        showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
        console.log(response.error);
      }
    });
  };

  const showSweetAlertDeleteTransaction = (transaction: any) => {
    setSweetAlertDeleteTransaction(
      <SweetAlert
        warning
        title="Atenção!"
        onConfirm={() => handleDeleteTransaction(transaction)}
        onCancel={() => setSweetAlertDeleteTransaction(undefined)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Sim"
        cancelBtnBsStyle="secondary"
        cancelBtnText="Não"
        showCancel
      >
        Excluir a transação "{transaction.OFE_CODIGO}" do associado "{transaction.OFE_TITULO}"?
      </SweetAlert>
    );
  };

  return (
    <LayoutMain isLoading={isLoading} title="Cancelar Transação" searchBoxState={{ searchBoxValue, setSearchBoxValue }}>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <CardHeader
              title="Cancelamento de Transação"
              subtitle="Aplique os filtros por data e status"
              filterAction={() => openModal('ModalFilterVisistas')}
              extraActions={[{ title: 'Exportar para Excel', iconClass: 'far fa-file-excel', action: exportToExcel }]}
            />
            <ModalNegotiationHistory isLoading={isLoadingHistory} history={historico} />
            {sweetAlertDeleteTransaction}

            <div className="card-body">
              <TableWithLinks
                responsive
                isLoading={isLoading}
                emptyTableMessage="Tente alterar os filtros."
                columns={[
                  { field: 'OFE_CODIGO', label: 'Orçamento' },
                  { field: 'OFE_TITULO', label: 'Associado Permutante' },
                  { field: 'EMPTY', label: 'Oferta' },
                  { field: 'ONE_DATA', label: 'Últ. Atual.', alignment: 'text-center' },
                  { field: 'ONE_DESCRICAO', label: 'Resposta' },
                  { field: 'VALOR', label: 'Valor' },
                  { field: 'STATUS', label: 'Status Negociação', alignment: 'text-center' },
                  { field: 'TRANSACAO', label: 'Status Pagamento', alignment: 'text-center' },
                ]}
                actions={[
                  {
                    iconClass: 'fa fa-list icon-medium',
                    title: 'ver histórioco',
                    action: openHistory,
                  },
                  {
                    iconClass: 'far fa-times-circle',
                    title: 'Cancelar Transação',
                    variant: 'danger',
                    action: showSweetAlertDeleteTransaction,
                  },
                ]}
                data={transacoes.map((temp: any) => ({
                  ...temp,
                  EMPTY: '--',
                  VALOR: formatNumberToMoney(temp.OFE_VALOR),
                  STATUS: (
                    <>
                      <i className={statusStyle[temp.ONE_STATUS].iconClass} /> {statusStyle[temp.ONE_STATUS].value}
                    </>
                  ),
                  TRANSACAO: temp.TRA_CODSTTPEDSITVEN.split('-') ?? temp.TRA_CODSTTPEDSITCOM.split('-') ?? '',
                }))}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="ModalFilterVisistas" role="dialog" tabIndex={-1}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body" style={{ paddingTop: 0 }}>
              <div className="row">
                <div className="col-md-12">
                  <div className="card stacked-form">
                    <div className="card-header ">
                      <h4 className="card-title">Buscar transações...</h4>
                    </div>
                    <div className="card-body " style={{ paddingTop: 0 }}>
                      <form onSubmit={handleSubmit(handlerFilterServerSide)} id="ModalFilterVisistasId">
                        <BasicInput
                          control={control}
                          type="date"
                          defaultValue={moment().subtract(3, 'd').format('YYYY-MM-DD')}
                          name="data_inicio"
                          label="Data início"
                        />

                        <BasicInput
                          control={control}
                          type="date"
                          defaultValue={moment().add(3, 'd').format('YYYY-MM-DD')}
                          name="data_final"
                          label="Data fim"
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-wd btn-default btn-outline" data-dismiss="modal">
                <i className="fa fa-arrow-left"></i> Voltar
              </button>
              <button className="btn btn-default btn-wd" form="ModalFilterVisistasId" type="submit">
                <i className="fa fa-search"></i> Buscar
              </button>
            </div>
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default CancelarTransacao;
