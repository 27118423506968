import React, { useContext, useState } from 'react';
import Select from 'react-select';
import Footer from '~/components/footer';
import Header from '~/components/header';
import Menu from '~/components/menu';
import { showAlertDanger, showAlertSuccess } from '~/components/notification';
import TableWithLinks from '~/components/table/TableWithLinks';
import { AuthContext } from '~/context/AuthContext';
import { getEquipesGestores, removeGestor, saveGestor } from '~/service/CarteiraEquipes';

import '~/utils/extendsType';

const CarteiraAssociados: React.FC = () => {
  const { company } = useContext(AuthContext);

  const [consultores, setConsultores] = useState([]);
  const [associados, setAssociados] = useState([]);
  const [associadosLivres, setAssociadosLivres] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selecionado, setSelecionado] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const selectRef = React.useRef(null);

  const [render, setRender] = useState(true);

  React.useEffect(() => {
    if (render) {
      setIsLoading(true);

      getEquipesGestores(company.EMP_CODIGO).then((response) => {
        setConsultores(response?.gerentes ?? []);
        setAssociados(response?.consultores ?? []);

        setIsLoading(false);
        setRender(false);
      });
    }
  }, [render]);

  const handlerChangeConsultor = (consultor: any) => {
    const consultorSelecionado: any = consultores.find((c: any) => c.USU_CODIGO == consultor.value);

    setSelecionado(consultorSelecionado);

    const associadosVinculadosIds = consultores.flatMap((c: any) => c.consultores.map((a: any) => a.USU_CODIGO));

    const associadosNaoVinculados = associados.filter((ass: any) => !associadosVinculadosIds.includes(ass.USU_CODIGO));

    setAssociadosLivres(associadosNaoVinculados);
  };

  const addToConsultor = (data: any) => {
    const updatedSelecionado = {
      ...selecionado,
      consultores: [...selecionado.consultores, data],
    };

    const updatedAssociadosLivres = associadosLivres.filter(
      (associado: any) => associado.USU_CODIGO !== data.USU_CODIGO
    );

    setSelecionado(updatedSelecionado);
    setAssociadosLivres(updatedAssociadosLivres);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredAssociadosLivres = associadosLivres.filter((associado: any) =>
    associado.EMP_RAZAOSOCIAL.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const saveList = async (e: any) => {
    const btn = e.target.loader();

    btn.start();

    const { success, error } = await saveGestor(selecionado);

    btn.reset();

    setRender(success);

    if (success) {
      showAlertSuccess({ message: 'Consultor vinculado com sucesso!' });
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
      if (error) console.log(error);
    }
  };

  const removeList = async (data: any) => {
    const { success, error } = await removeGestor({ USU_CODIGO: data['USU_CODIGO'] });

    setRender(success);

    if (success) {
      showAlertSuccess({ message: 'Consultor desvinculado com sucesso!' });

      const updatedSelecionado = {
        ...selecionado,
        consultores: selecionado.consultores.filter((a: any) => a.USU_CODIGO !== data['USU_CODIGO']),
      };

      setSelecionado(updatedSelecionado);
    } else {
      showAlertDanger({ message: 'Ocorreu um erro inesperado. Por favor, tente novamente.' });
      if (error) console.log(error);
    }
  };

  return (
    <div className="wrapper">
      <Menu />
      <div className="main-panel">
        <Header title="Consultores - Equipes" />

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <p className="text-center">Gestores</p>
                    <Select
                      ref={selectRef}
                      onChange={handlerChangeConsultor}
                      options={consultores.map((consultor: any) => ({
                        value: consultor.USU_CODIGO,
                        label: consultor.USU_NOME,
                      }))}
                      placeholder="Selecione um gerente"
                    />
                    <hr />
                    <p className="text-center">Consultores Livres</p>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Pesquisar"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                    <ul className="list-group list-group-flush">
                      {filteredAssociadosLivres.length === 0 && (
                        <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                          Nenhum consultor livre encontrado
                        </li>
                      )}
                      {filteredAssociadosLivres.map((associado: any) => (
                        <li
                          key={`list-${associado.USU_CODIGO}`}
                          className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                        >
                          #{associado.USU_CODIGO} - {associado.USU_NOME}{' '}
                          <i
                            className="fas fa-plus"
                            onClick={() => addToConsultor(associado)}
                            style={{ fontSize: 18, cursor: 'pointer' }}
                          ></i>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-sm-12">
                <div className="card">
                  <div className="card-header card-header-primary">
                    <h4 className="card-title">Carteira de Consultores</h4>
                    <p className="card-category">Veja a lista de consultores</p>
                    <button
                      onClick={saveList}
                      type="button"
                      className="btn btn-sm btn-success btn-outline"
                      style={{ marginTop: 10 }}
                    >
                      <span className="btn-label">
                        <i className="fa fa-plus"></i>
                      </span>
                      salvar lista
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <div className="card-body table-full-width">
                        <TableWithLinks
                          isLoading={isLoading}
                          emptyTableMessage="Nenhum registro de visita encontrada."
                          columns={[
                            { field: 'USU_NOME', label: 'Consultor', alignment: 'text-left' },
                            { field: 'USU_EMAIL', label: 'Email', alignment: 'text-left' },
                          ]}
                          actions={[
                            {
                              iconClass: 'far fa-times-circle',
                              title: 'Excluir',
                              style: { color: 'red' },
                              action: removeList,
                            },
                          ]}
                          data={selecionado?.consultores ?? []}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default CarteiraAssociados;
