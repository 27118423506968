import { showAlertDanger } from '~/components/notification';
import { Month } from '~/types';

const formatNumberToCurrency = (value: number | bigint, signal: boolean = false) => {
  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    signDisplay: 'auto',
  })
    .format(value)
    .replace('R$', '')
    .split(/\s/g)
    .join('');
};

export const formatNumberToMoney = (value: number | bigint) => 'R$ ' + formatNumberToCurrency(value);
export const formatNumberToVPS = (value: number | bigint) => 'VP$ ' + formatNumberToCurrency(value);
export const formatNumberWithoutPrefix = (value: number | bigint) => formatNumberToCurrency(value);
export const correctNumberWithComma = (value: string) => +value.replace(/R\$|\./g, '').replace(/,/g, '.');

export const compareStrings = (bigger: string, smaller: string) => {
  if (!bigger) return 1;
  if (!smaller) return -1;

  const biggerLowerCase = bigger.toLowerCase();
  const smallerLowerCase = smaller.toLowerCase();

  if (biggerLowerCase > smallerLowerCase) return 1;
  else if (smallerLowerCase > biggerLowerCase) return -1;
  else return 0;
};

export const compareDate = (bigger: Date, smaller: Date) => {
  if (!bigger) return 1;
  if (!smaller) return -1;

  const biggerTime = bigger.getTime();
  const smallerTime = smaller.getTime();

  if (biggerTime > smallerTime) return 1;
  else if (smallerTime > biggerTime) return -1;
  else return 0;
};

export const formatAndCompareDate = (bigger: string, smaller: string) => {
  if (!bigger) return 1;
  if (!smaller) return -1;

  const b = new Date(formatDateToDefaultPattern(bigger));
  const s = new Date(formatDateToDefaultPattern(smaller));

  return compareDate(b, s);
};

//Formatter used to format a number to component react-number-format
export const reactNumberFormatter = (value: string, withOutPrefix?: boolean) => {
  if (!Number(value)) return '';

  const amount = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(+value / 100);

  return withOutPrefix ? `${amount}`.replace('R$', '') : `${amount}`;
};

//Formatter used to corrector a number from component react-number-format
export const reactNumberCorrector = (value: string) => {
  return !value.includes(',') ? +value / 100 : +value.replace(/R\$|\./g, '').replace(/,/g, '.');
};

//Format a date in pattern DD/MM/YYYY HH:mm to pattern YYYY-MM-DD
export const formatDateToDefaultPattern = (date: string) => {
  const datetime = date.split(' ');
  const newDate = datetime[0].split('/').reverse().join('-');
  return datetime.length === 1 ? newDate : newDate + ' ' + datetime[1];
};

export const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;

export const copyToClipboard = async (textToCopy: string) => {
  try {
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(textToCopy);
      return true;
    } else {
      window.open(textToCopy);
      return false;
    }
  } catch (error: any) {
    console.error('erro ao copiar link para a área de transferência');
    console.error(error.message);
    console.log('window.isSecureContext: ' + window.isSecureContext);
  }
};

export const openModal = (modalId: string) => {
  if (window.getSelection()?.toString() === '') $(`#${modalId}`).modal('show');
};

export const closeModal = (modalId: string) => {
  $(`#${modalId}`).modal('hide');
};

export const addJQueryListener = (modalId: string, eventName: string, listener: any) => {
  $(`#${modalId}`).on(eventName, listener);
};

export const highlightModal = (modalId: string) => {
  $($(`#${modalId}`).data('bs.modal')._backdrop).css('z-index', 1051);
};

export const jQueryChangeCSS = (elementId: string, cssKey: string, value: string | number) => {
  $(`#${elementId}`).css(cssKey, value);
};

export const isPopupBlocked = () => {
  const popup = localStorage.getItem('popup');

  if (popup && popup === 'true') {
    return false;
  }

  var oWin = window.open(
    'https://shopline.itau.com.br/shopline/shopline.asp',
    'go',
    'width=1,height=1,top=900,left=900'
  );

  if (oWin == null || typeof oWin == 'undefined') {
    return true;
  }

  localStorage.setItem('popup', 'true');

  oWin.close();
  return false;
};

export const openItauShopline = (DC: string) => {
  const form = document.createElement('form');
  form.action = 'https://shopline.itau.com.br/shopline/shopline.asp';
  form.method = 'post';
  form.target = '_blank';

  const input = document.createElement('input');
  input.name = 'DC';
  input.value = DC;

  form.appendChild(input);

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

export const formatPhone = (phone: string) =>
  phone ? phone.replace(/^(\d\d)(\d)(\d{4})(\d{4}).*/, '($1) $2 $3-$4') : phone;

export const formatCPF = (cpf: string) => (cpf ? cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4') : cpf);

export const formatCNPJ = (cpf: string) =>
  cpf ? cpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5') : cpf;

export const randomColorGoldenAngle = (number: number, intensity: 'HIGH' | 'NORMAL' | 'LOW' = 'NORMAL') => {
  if (Number(number)) '';
  const hue = (number < 1 ? 1 : number) * 137.508; // use golden angle approximation
  return `hsl(${hue}, ${intensity === 'NORMAL' ? '50%, 75%' : intensity === 'HIGH' ? '100%, 50%' : ''})`;
};

export const selectColorPerColorPalette = (
  colorIndex: number,
  colorPaletteSize: number,
  intensity: 'HIGH' | 'NORMAL' | 'LOW' = 'NORMAL'
) => {
  if (Number(colorIndex) || Number(colorPaletteSize)) '';
  if (colorPaletteSize < 1) colorIndex = 1; // defaults to one color - avoid divide by zero
  return `hsl(${(colorPaletteSize * (360 / colorIndex)) % 360}, ${intensity === 'NORMAL' ? '50%, 75%' : intensity === 'HIGH' ? '100%, 50%' : ''
    })`;
};

export const abbreviateNumber = (num: any, fixed: number = 0): string => {
  if (num === null) {
    return '';
  } // terminate early
  else if (num === 0) {
    return '0';
  } // terminate early
  else if (num < 1000 && num > -1000) {
    return formatNumberWithoutPrefix(num);
  }

  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  var b = num.toPrecision(2).split('e'), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
  return e;
};

/**
 * Get the abbreviated name of the month according to the month number in a range 1-12.
 * @param month The month number in the range 1-12.
 * @returns Abbreviated month name.
 */
export const getMonth = <T extends 'abbreviated' | 'extensive' | 'number' | undefined>(
  month: string | number,
  type?: T
): T extends 'number' ? Month<number> : Month<string> => {
  const monthsExtensive = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  const monthsAbbreviated = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

  return (
    typeof month === 'string'
      ? type === 'number'
        ? monthsAbbreviated.indexOf(month) + 1
        : type === 'extensive'
          ? monthsExtensive[monthsAbbreviated.indexOf(month)]
          : monthsAbbreviated[monthsExtensive.indexOf(month)]
      : type === 'number'
        ? month
        : type === 'extensive'
          ? monthsExtensive[+month - 1]
          : monthsAbbreviated[+month - 1]
  ) as any;
};

export const getRandomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min;

export const openInNewWindow = (url: string) => window.open(url, '_blank', 'noopener noreferrer');

export const parseTransactionArray = (
  CODSTTPEDSIT: { TRA_CODSTTPEDSITVEN?: string; TRA_CODSTTPEDSITCOM?: string },
  isSell: boolean
) => {
  const transactionArray = isSell
    ? CODSTTPEDSIT.TRA_CODSTTPEDSITVEN?.split('-')
    : CODSTTPEDSIT.TRA_CODSTTPEDSITCOM?.split('-');

  return transactionArray
    ? {
      tra_codigo: transactionArray[0],
      tra_status: transactionArray[1],
      tra_pedido: transactionArray[2],
      tra_situacao: transactionArray[3],
      tra_dc: transactionArray[4],
    }
    : null;
};

export const downloadDocuments = async (url: string, name: string, setAttribute: boolean, typeValidate: string | undefined = undefined) => {

 return fetch(url, {
    method: 'GET',
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));

      if (typeValidate) {
        const mimeType = blob.type;
        const validMimeTypes = [typeValidate]; // Adicione os tipos MIME válidos aqui

        if (!validMimeTypes.includes(mimeType)) {
          showAlertDanger({ message: 'Orçamento em negociação, voucher ainda não foi gerado!' });
          throw 'A resposta não é um arquivo válido.';
        }
      }

      const link = document.createElement('a');
      link.href = url;

      if (setAttribute) {
        link.setAttribute('download', name);
      } else {
        link.download = name;
      }

      document.body.appendChild(link);
      link?.click();
      link?.parentNode?.removeChild(link);
    });

};
