import React, { useContext } from 'react';

import { AuthContext } from '~/context/AuthContext';
import { downloadDocuments } from '~/utils/util';
import { baseURL } from '~/config/api';
import LayoutMain from '~/layout/main';
import CardHeader from '~/components/card/CardHeader';

import '../../../utils/extendsType';

const RelatorioSaldoAssociados: React.FunctionComponent = () => {
  const { company } = useContext(AuthContext);

  const exportToExcel = (e: any) => {
    const params = new URLSearchParams();

    params.append('c', company.EMP_CODIGO.toString());
    params.append('m', company.EMP_CODMATRIZ.toString());
    const url = `${baseURL}/api/relatorios/exportar-saldo-associados?${params.toString()}`;

    const btn = e.currentTarget.loader();

    btn.start();

    downloadDocuments(url, `SaldoAssociados.xls`, false).finally(() => {
      btn.reset();
    });
  };

  return (
    <LayoutMain title="Saldo Associados">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <CardHeader
              title="Saldo Associados"
              subtitle="Exportar relatório sobre o saldo dos associados"
              actions={[
                {
                  iconClass: 'far fa-file-excel',
                  label: ' Exportar relatório para Excel',
                  action: exportToExcel,
                  variant: 'btn-primary',
                },
              ]}
            />
          </div>
        </div>
      </div>
    </LayoutMain>
  );
};

export default RelatorioSaldoAssociados;
